<script>
import Proposal from '../services/Proposal';
import moment from 'moment';
import Clipboard from 'clipboard';


export default {
    name: "indexClickSign",
    methods: {
        getSignersNotSigned(item) {
            let signers = []
            item.signers?.forEach(i => {
                if (i.signed_at === null) {
                    signers.push(i);
                }
            })
            if (signers?.length) return signers;
            return null;
        },
        async notifyAllPendin(item) {
            console.log(item);
            await this.$swal.fire({
                title: this.t('ASSIGN.REALLY_NOTIFY_PENDING'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let signers = this.getSignersNotSigned(item)
                    if (signers.length) {
                        this.$store.commit('changeLoading', true);
                        for (const signer of signers) {
                            await Proposal.watsappClickSing(signer)
                        }
                        this.$store.commit('changeLoading', false);
                        this.toast.success('Notificação Enviada');
                    }
                }
            });
        },
        showDocumentSigned(document) {
            this.$store.commit('changeLoading', true);
            Proposal.showDocumentClickSign(document.id).then((resp) => {
                this.$store.commit('changeLoading', false);
                if (resp.data.validations) return;
                window.open(resp.data.signed_file_url, '_blank');
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        sendWhatsapp(signer) {
            let companies = JSON.parse(localStorage.getItem('companies'));
            companies.forEach((i) => {
                if (i.id === signer.company_id) {
                    signer.company_name = i.text;
                }
            })
            this.$swal.fire({
                title: this.t('PROPOSAL.ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.watsappClickSing(signer).then((resp) => {
                        this.$store.commit('changeLoading', false);
                        if (resp.data.message) {
                            this.toast.success(resp.data.message);
                        }
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.toast.error(err.response.data.message);
                    })
                }
            });
        },
        selectClass(status) {
            switch (status) {
                case null:
                    return 'signer-running'
                case 'signed':
                case 'closed':
                    return 'signer-signed'
                case 'canceled':
                    return 'signer-canceled'
                case 'expired':
                    return 'signer-expired'
            }
        },
        showDocumentOrigimal(id) {
            this.$store.commit('changeLoading', true);
            Proposal.showDocumentClickSign(id).then((resp) => {
                if (resp.data.original_file_url) {
                    window.open(resp.data.original_file_url, '_blank');
                }
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                if (err.response?.data?.validations?.message) {
                    this.toast.error(err.response.data.validations.message);
                }
            });
        },
        formatDate(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY H:mm')
            }
            return "";
        },
        signaturesQuantity(item) {
            let alreadySigned = [];

            item.signers.forEach((signed) => {
                if (signed.status_of_signature === 'signed') {
                    alreadySigned.push('assinou')
                }
            });

            return alreadySigned.length + "/" + item.signers.length
        },
        translate(word) {
            switch (word) {
                case null:
                    return 'Aguardando';
                case 'signed':
                    return 'Assinado';
                case 'running':
                    return 'Aguardando';
                case 'canceled':
                    return 'Cancelado';
                case 'closed':
                    return 'Assinado';
                case 'sign':
                    return 'Assinar';
                case 'approve':
                    return 'Aprovador';
                case 'party':
                    return 'Participante';
                case 'witness':
                    return 'Testemunha';
                case 'intervening':
                    return 'Interveniente';
                case 'receipt':
                    return 'Recebimento';
                case 'endorser':
                    return 'Endossante';
                case 'endorsee':
                    return 'Endossatário';
                case 'administrator':
                    return 'Administrador';
                case 'guarantor':
                    return 'Avalista';
                case 'transferor':
                    return 'Cedente';
                case 'transferee':
                    return 'Cessionário';
                case 'contractee':
                    return 'Contratada';
                case 'contractor':
                    return 'Contratante';
                case 'joint_debtor':
                    return 'Devedor Solidário';
                case 'issuer':
                    return 'Emitente';
                case 'manager':
                    return 'Gestor';
                case 'buyer':
                    return 'Comprador';
                case 'seller':
                    return 'Vendedor';
                case 'attorney':
                    return 'Procurador';
                case 'legal_representative':
                    return 'Representante Legal';
                case 'co_responsible':
                    return 'Responsável Solidário';
                case 'validator':
                    return 'Validador';
                case 'ratify':
                    return 'Homologador';
                case 'lessor':
                    return 'Locador';
                case 'lessee':
                    return 'Locatário';
                case 'surety':
                    return 'Fiador';
                case 'broker':
                    return 'Corretor';
            }
        },
        open(client) {
            client.open = !client.open ? true : false;
        },
        copyToClipboard(text) {
            const clipboard = new Clipboard('.btn-primary', {
                text: function () {
                    return process.env.VUE_APP_CLICK_SIGN_HOST + `/sign/${text}`;
                }
            });

            clipboard.on('success', (e) => {
                this.toast.success('Link copiado com sucesso!');
                clipboard.destroy();
                e.clearSelection();
            });

            clipboard.onClick({currentTarget: document.querySelector('.btn-primary')});
        },
    }
}
</script>

<style scoped>

</style>